<template>
  <div>
    <transition name="modal-fade">
      <div v-if="showCustomModal" class="custom-modal-overlay" @click="showCustomModal = false">
        <div class="custom-modal mx-3 mx-md-0" @click.stop>
          <div class="custom-modal-header">
            <slot name="header"></slot>
            <button v-if="closeBtn" @click="showCustomModal = false"><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<line y1="-0.5" x2="21.7472" y2="-0.5" transform="matrix(0.712043 -0.702136 0.821677 0.569953 1 16.2695)" stroke="#050505"/>
<line y1="-0.5" x2="21.7472" y2="-0.5" transform="matrix(-0.712043 -0.702136 0.821677 -0.569953 16.4854 16.2695)" stroke="#050505"/>
</svg>
</button>
          </div>
          <div class="custom-modal-body">
            <slot></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props:{
    closeBtn:Boolean,
  },
  data() {
    return {
      showCustomModal: false
    };
  },
  methods:{
    open(){
      this.showCustomModal = true
    },
     close(){
      this.showCustomModal = false
    },
  }
};
</script>

<style>
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(80, 58, 60, 0.50);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1111111111;
}

.custom-modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 528px;
  
  border-radius: 20px;
    border: 2px solid #000;
}

.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-modal-header h2 {
  margin: 0;
}

.custom-modal-header button {
  border: none;
  background: none;
  cursor: pointer;
  color: #999;
}

.custom-modal-body {
  margin-top: 20px;
}

.modal-fade-enter-active, .modal-fade-leave-active {
  transition: opacity 0.5s;
}

.modal-fade-enter, .modal-fade-leave-to /* .modal-fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
