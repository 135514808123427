<template>
        <button @click="$emit('click')" class="btn elt-btn" :disabled="isLoading" :class="[variant=='fill'?'filled-btn':'bordered-btn']">
            <b-spinner small v-show="isLoading"></b-spinner>
     <span v-show="!isLoading">
    <slot></slot>
    </span>
    </button>
</template>
<script>
export default {
    props:{
        variant:{
            type:String,
            default:'fill'
        },
        isLoading:{
            type:Boolean,
            default:false,
        },
       
    }
}
</script>

<style scoped>

@media screen and (max-width: 768px)
{

  .elt-btn{ 
      min-width: 149.255px!important;
    font-size: 16px!important;
font-style: normal;
font-weight: 500!important;;
  }
}
.elt-btn{
border-radius: 20px;
min-width: 168px;
text-align: center;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 500;

}
.elt-btn:focus-visible{
  outline: 1px solid #503A3C;
}

.bordered-btn{
color: #503A3C;
border:1px solid #503A3C;
}
.filled-btn{
    color:#fff;
     background-color: #503A3C; 
     border:none;
}
</style>